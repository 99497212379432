/** @jsx jsx */
import React from "react"
import { jsx } from "theme-ui"
import Illustration from "../../images/illustrations/hero-howitworksindividual.jpg"
import { Link } from "gatsby"

export default function Hero(offset) {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div
      className="hero image-as-background"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.6)), url(${Illustration})`,
      }}
    >
      <div className="relative overflow-hidden hero-gradient pt-12 pb-12">
        <div className="max-w-screen-xl mx-auto p-6 md:pt-12 sm:p-8">
          <div className="flex justify-center">
            <div className="mt-4">
               <h2
                    style={{ fontSize: 24, color: "#ffffff"}}
                    className=" text-center pb-12 tracking-tight leading-8 sm:leading-none font-bold text-3xl sm:text-3xl md:text-3xl lg:text-5xl"
                  >
                    YOU AND YOUR COACH
                  </h2>
                  <h1 style={{color: "#ffffff"}} className="huge-font text-white text-center tracking-tight leading-8 sm:leading-none font-bold">
                    PARTNER
                  </h1>
                  <h2
                    style={{ color: "#ffcc00"}}
                    className="text-center tracking-tight leading-8 sm:leading-none font-bold med-font text-3xl sm:text-3xl md:text-3xl lg:text-5xl"
                  >
                    TO ACHIEVE YOUR SUCCESS
                  </h2>

                  <p className="text-center mt-3 sm:mt-5 text-xs sm:text-md md:text-lg font-bold" style={{ maxWidth: 800, fontSize: 22, color: "#ffffff", }}>
                    Tiny goals or Big Hairy Audacious Goals (BHAG) Being Zone helps you reach every milestone you set. It's your success journey.
                  </p>
                    <div className="text-center mt-6 sm:mt-8 sm:flex sm:justify-center lg:justify-center">

                    <div className="">
                    <Link
                      to={"/pricing"}
                      className="cursor-pointer shadow bg-zone-cta focus:shadow-outline focus:outline-none text-white font-bold py-3 px-6 rounded"
                    >
                      Get Started
                    </Link>
                    </div>
                  </div>


            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
