import React, { useEffect } from "react"
import Layout from "../components/layout"
import HeroHowItWorks from "../components/how-it-works-individuals/hero-how-it-works"
import QuoteBlock from "../components/how-it-works-individuals/quote"
import Reflaction from "../components/how-it-works-individuals/reflaction"
import Works from "../components/how-it-works-individuals/works"
import Coachingpsyc from "../components/how-it-works-individuals/coachingpsyc"


const HowItWorks = () => {
  useEffect(() => {
    console.log("🔥 Initializing Firebase Analytics & Performance 🔥")
    // firebase.analytics()
    // firebase.performance() 
  }, [])

  return (
    <Layout>
      <HeroHowItWorks />
      <QuoteBlock />
      <Reflaction />
      <Works/>
    </Layout>
  )
}

export default HowItWorks
