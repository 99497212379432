/** @jsx jsx */
import React from "react"
import { jsx } from "theme-ui"
import atob from "../../images/illustrations/atob.png"
import styled from "styled-components"

const Header = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
`

export default class Schedule extends React.Component {
  state = {
    name: "",
    email: "",
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    })

    this.setState({
      mailToString:
        "/schedule-demo?name=" + this.state.name + "&email=" + this.state.email,
    })
  }

  render() {
    return (
      <div>
        <div className="relative overflow-hidden">
          <div className="mx-auto p-6 md:pt-12 sm:p-8">
          <Header>
          <div>

            <h2
              style={{
                fontWeight: 800,
              fontSize: 28,
              }}
              className="tracking-tight font-bold sm:leading-none text-2xl sm:text-lg md:text-4xl mb-2"
            >
              GET IGNITED AND INITIATED 
            </h2>
      
            <h2
              style={{
                fontWeight: 800,
                fontSize: 36,
                color: "#cc3366",
              }}
              className="uppercase tracking-tight font-bold sm:leading-none text-2xl sm:text-lg md:text-4xl"
            >
              Being in Flow
            </h2>
            <p
              style={{
                fontWeight: 600,
                fontSize: 20,
              }}
              className="mt-2 mb-8 text-xs sm:text-sm md:text-md para"
            >
              The Four Layers of Flow
            </p>
          </div>
        </Header>
         <div className="md:flex p-4 justify-center mb-2">
          <div className="flex flex-col w-full" style={{ alignItems: "center"}}>
            <img
             style={{ maxWidth: 600 }}
                    className="w-auto h-auto"
            src={atob}
            alt="illustration"
            />
          </div>
        </div>
            <div className="md:flex p-4 justify-center">
              <div className="ml-8 mr-8 flex flex-col w-full md:w-1/4 lg:w-1/4 xl:w-1/4 ">
               <div className="" >
                    <h2
                      style={{
                        fontWeight: 800,
                        fontSize: 20,
                      }}
                      className=" mt-4 uppercase tracking-tight leading-8 sm:leading-none font-bold text-3xl sm:text-3xl md:text-3xl lg:text-5xl"
                    >
                      Determination 
                    </h2>

                    <p
                      style={{
                        fontSize: 14,
                      }}
                      className="mt-1 para"
                    >
                      
                  "WILLINGNESS" how far are you ready? How strong is your desire? Why? Your first step.

                    </p>
                </div>
              </div>
              <div className="ml-8 mr-8 flex flex-col w-full md:w-1/4 lg:w-1/4 xl:w-1/4 ">
               <div className="" >
                    <h2
                      style={{
                        fontWeight: 800,
                        fontSize: 20,
                      }}
                      className=" mt-4 uppercase tracking-tight leading-8 sm:leading-none font-bold text-3xl sm:text-3xl md:text-3xl lg:text-5xl"
                    >
                      Behavior Change 
                    </h2>

                    <p
                      style={{
                        fontSize: 14,
                      }}
                      className="mt-1 para"
                    >
                      
                  Step two, "ATTITUDE," learn to identify and break the pattern. Start building a constructive routine.

                    </p>
                </div>
              </div>
              <div className="ml-8 mr-8 flex flex-col w-full md:w-1/4 lg:w-1/4 xl:w-1/4 ">
               <div className="" >
                    <h2
                      style={{
                        fontWeight: 800,
                        fontSize: 20,
                      }}
                      className=" mt-4 uppercase tracking-tight leading-8 sm:leading-none font-bold text-3xl sm:text-3xl md:text-3xl lg:text-5xl"
                    >
                      Motivation 
                    </h2>

                    <p
                      style={{
                        fontSize: 14,
                      }}
                      className="mt-1 para"
                    >
                      
                  Each wins count and boosts your self-confidence. Find your intrinsic and extrinsic motivation to move "FORWARD."

                    </p>
                </div>
              </div>
              <div className="ml-8 mr-8 flex flex-col w-full md:w-1/4 lg:w-1/4 xl:w-1/4 ">
               <div className="" >
                    <h2
                      style={{
                        fontWeight: 800,
                        fontSize: 20,
                      }}
                      className=" mt-4 uppercase tracking-tight leading-8 sm:leading-none font-bold text-3xl sm:text-3xl md:text-3xl lg:text-5xl"
                    >
                      Flow 
                    </h2>

                    <p
                      style={{
                        fontSize: 14,
                      }}
                      className="mt-1 para"
                    >
                  Don't go with the flow, be in the FLOW. "Being in the Zone" is to be focused, accountable, curious, and expressive.

                    </p>
                </div>
              </div>

              
            </div>


<div className="md:flex p-2 justify-center mb-6">
          <div className="flex flex-col w-3/4">
            <p className="mt-4 text-center font-bold para uppercase">
              "Being Yourself" means "Showing up and Doing - the Necessary" every time, day in and day out.
            </p>
          </div>
        </div>

          </div>
        </div>
      </div>
    )
  }
}
