/** @jsx jsx */
import { jsx } from "theme-ui"
import styled from "styled-components"
import icon_clarity from "../../images/icons/search.png"
import icon_strength from "../../images/icons/dumbbell.png"
import icon_awareness from "../../images/icons/aware.png"
import icon_confidence from "../../images/icons/confidence.png"
import icon_communication from "../../images/icons/conversation.png"
import icon_strategy from "../../images/icons/strategy.png"
import icon_actionplan from "../../images/icons/target.png"
import icon_360 from "../../images/icons/self-confrontation.png"

const Header = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const features = [
  {
    heading: "Clarity",
    subheading: "Understanding clear picture of what you want",
    icon: icon_clarity,
    note: "Feeling Certain",
  },
  {
    heading: "Strength",
    subheading: "Building and remembering our strengths",
    icon: icon_strength,
    note: "Endless Potential",
  },
  {
    heading: "Awareness",
    subheading: "Know your beliefs, attitudes, and perspective",
    icon: icon_awareness,
    note: "Being Conscious",
  },
  {
    heading: "Confidence",
    subheading: "It matters the most; learn to play with your strengths",
    icon: icon_confidence,
    note: "Always Show Up",
  },
   {
     heading: "Communication",
    subheading: "Is the bridge within yourself and with others",
    icon: icon_communication,
    note: "Human Connection",
  },
  {
    heading: "Strategy",
    subheading: "Create a plan for short-term and long-term",
    icon: icon_strategy,
    note: "No other route",
  },
  {
    heading: "Action",
    subheading: "Lets do it. The only thing that defines you",
    icon: icon_actionplan,
    note: "Doing (part of Being)",
  },
  {
    heading: "360 Degree",
    subheading: "Create feedback loop and reflection space",
    icon: icon_360,
    note: "Magic Mirror",
  },
   /*{
    heading: "Goal",
    subheading: "Where do you want to go?   ",
    icon: icon_goal,
    note: "Assessment, clarity and Reality",
  },
  {
    heading: "Explore",
    subheading: "The Vantage Point    ",
    icon: icon_explore,
    note: "Beliefs, Values, Attitudes and Perspective",
  },
  {
    heading: "Action Plan    ",
    subheading: "Realistic and Achievable    ",
    icon: icon_actionplan,
    note: "Options and Strategy",
  },
  {
    heading: "Reflection",
    subheading: "Magic Mirror",
    icon: icon_reflection,
    note: "Accountability and Follow up",
  },*/
]

const renderFeature = () => {
  let featuresData = []
  features.forEach((feature, index) => {
    featuresData.push(
      <div
        key={index}
        className="flex flex-col text-center w-full md:w-1/4 lg:w-1/4 xl:w-1/4 mb-4"
      >
        <div className="self-center mt-2">
          <div
            className="flex justify-center"
            style={{
              width: "70px",
              height: "70px",
              borderRadius: "8px",
            }}
          >
            <img
              style={{
                width: "70px",
                height: "auto",
              }}
              className="self-center"
              src={feature.icon}
              alt="illustrations"
            />
          </div>
        </div>

        <h1
          className="uppercase ml-8 mr-8"
          style={{ fontSize: 24 }}
        >
          {feature.heading}
        </h1>
        <div className="self-center">
          <p
            style={{ width: 220, fontSize: 16 }}
            className=" ml-8 mr-8 text-xs sm:text-sm md:text-md"
          >
            {feature.subheading}
          </p>
          <p
            style={{ width: 220, fontSize: 16 }}
            className="text-center mt-2 uppercase font-bold ml-8 mr-8 text-xs sm:text-sm md:text-md"
          >
            {feature.note}
          </p>
        </div>
      </div>
    )
  })
  return featuresData
}

const Reflaction = ({ offset }) => (
  <div>
    <div className="relative overflow-hidden">
      <div className="max-w-screen-xl mx-auto p-6 md:pt-6 sm:p-8">
        <Header>
          {/*  */}
        
          <h2
            style={{
              fontWeight: 800,
              fontSize: 28,
            }}
            className="uppercase text-center tracking-tight leading-8 sm:leading-none font-bold text-3xl sm:text-3xl md:text-3xl lg:text-5xl mb-2"
          >
            Build your muscles
          </h2>
          <h2 
           style={{
                fontWeight: 800,
                fontSize: 36,
                color: "#cc3366",
              }}
          className="uppercase text-center tracking-tight leading-8 sm:leading-none font-bold text-3xl sm:text-3xl md:text-3xl lg:text-5xl mb-2">
            Being Strong Being Better
          </h2>
          
        </Header>
        
        <div className="mt-8 flex flex-wrap">{renderFeature()}</div>
         
      </div>

    </div>
  </div>
)

export default Reflaction
